import React, { useState, useEffect, useRef } from "react";

//Material UI imports
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Pagination,
  Autocomplete,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

//Firebase imports
import { db } from "../firebase";
import { ref, orderByChild, query, get } from "firebase/database";

//Component imports
import { useAuth } from "../context/AuthContext";
import { useDarkMode } from "../context/DarkModeContext";
import LastModifiedByText from "../components/LastModifiedByText";
import SortByPopover from "../components/SortByPopover";
import { useNavigate } from "react-router-dom";
import Colors from "../data/Colors";

const createFilterOptions = (options, state) => {
  const { inputValue } = state;

  // If there's no value in the textfield, don't display any options
  if (!inputValue) {
    return [];
  }

  // If there is a value in the textfield, filter options based on input value
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  return filteredOptions;
};

const Repository = () => {
  const navigate = useNavigate();

  const [songList, setSongList] = useState([]);
  const [countDisplay, setCountDisplay] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [pageState, setPageState] = useState(1);

  const [normalSongArr, setNormalSongArr] = useState([]);
  const [normalSongNameData, setNormalSongNameData] = useState([]);
  const [hymnSongArr, setHymnSongArr] = useState([]);
  const [hymnSongNameData, setHymnSongNameData] = useState([]);
  const [paginateArr, setPaginateArr] = useState([]);
  const [searchOn, setSearchOn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchArr, setSearchArr] = useState([]);
  const [songTypePref, setSongTypePref] = useState("canto");
  const [LastAuthorEmail, setLastAuthorEmail] = useState("N/A");
  const [LastAuthorTime, setLastAuthorTime] = useState("N/A");

  const searchInput = useRef(null);

  const { userID } = useAuth();
  const { darkMode } = useDarkMode();

  const handleListItemClick = (e, item) => {
    e.preventDefault();
    navigate("/songdetail", {
      state: {
        item: item,
      },
    });
  };

  const handleAddSong = (e) => {
    e.preventDefault();
    navigate("/songdetail", {
      state: {
        item: null,
      },
    });
  };

  const movePage = (currPage) => {
    setLoading(true);
    setSongList([]);

    if (searchOn) {
      const newList = searchArr.slice(
        10 * (currPage - 1),
        10 * (currPage - 1) + 10
      );
      setSongList(newList);
      setLoading(false);
    } else {
      setSongList(paginateArr.slice(10 * currPage - 10, 10 * currPage));
      setLoading(false);
    }
  };

  const cancelActiveSearch = () => {
    if (searchOn || searchText) {
      const newList = paginateArr.slice(paginateArr[0], 10);
      setPageCount(Math.ceil(paginateArr.length / 10));
      setSongList(newList);
      setSearchText("");
      setSearchOn(!searchOn);
      setPageState(1);
    }
  };

  const sortData = (choice) => {
    //clear any active searches before sorting
    cancelActiveSearch();

    //HERE BEGINS THE SORTING OF DATA
    let tempArr;

    switch (choice) {
      case 0:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(false);
        setPageState(1);
        break;
      case 1:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.name > b.name ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(false);
        setPageState(1);
        break;
      case 2:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.performNum < b.performNum ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(true);
        setPageState(1);
        break;
      case 3:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.performNum > b.performNum ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(true);
        setPageState(1);
        break;
      default:
        break;
    }
  };

  //initial loading function
  useEffect(() => {
    const pageObj = query(ref(db, "articulos"), orderByChild("name"));
    let initTempArr = [];

    get(pageObj).then((snapshot) => {
      snapshot.forEach((item) => {
        if (item.val().type === "canto") {
          setNormalSongArr((current) => [...current, item.val()]);
          setNormalSongNameData((current) => [
            ...new Set([...current, item.val().name]),
          ]);
          initTempArr.push(item.val());
        } else {
          setHymnSongArr((current) => [...current, item.val()]);
          setHymnSongNameData((current) => [
            ...new Set([...current, item.val().name]),
          ]);
        }
      });

      setPageCount(Math.ceil(Object.values(initTempArr).length / 10));
      let tempArr = initTempArr.slice(0, 10);
      setSongList(tempArr);
      setPaginateArr(initTempArr);
    });

    get(ref(db, "LastModifiedData")).then((snapshot) => {
      if (snapshot.exists()) {
        setLastAuthorEmail(snapshot.val().repoLastAuthor);
        setLastAuthorTime(snapshot.val().repoLastTime);
      }
    });

    setLoading(false);
  }, []);

  return (
    <div
      style={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 2,
          paddingBottom: 1,
        }}
      >
        <Autocomplete
          disablePortal
          filterOptions={createFilterOptions}
          freeSolo
          disableClearable
          options={
            songTypePref === "canto" ? normalSongNameData : hymnSongNameData
          }
          value={searchText}
          sx={{ width: 360 }}
          onChange={(_, newValue, reason) => {
            if (reason === "selectOption") {
              setSearchText(newValue);
              setPageState(1);
              setSearchOn(true);
              const searchList = paginateArr.filter((i) =>
                i.name.toLowerCase().includes(newValue.toLowerCase())
              );
              setSearchArr(searchList);
              const newList = searchList.slice(0, 10);
              setSongList(newList);
              setPageCount(Math.ceil(searchList.length / 10));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={searchInput}
              label="Buscar"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value) {
                  setSearchText(e.target.value);
                  setPageState(1);
                  setSearchOn(true);
                  const searchList = paginateArr.filter((i) =>
                    i.name.toLowerCase().includes(e.target.value.toLowerCase())
                  );
                  setSearchArr(searchList);
                  const newList = searchList.slice(0, 10);
                  setSongList(newList);
                  setPageCount(Math.ceil(searchList.length / 10));
                  searchInput.current.blur();
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: searchText ? (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                    <IconButton onClick={cancelActiveSearch}>
                      <ClearIcon />
                    </IconButton>
                  </React.Fragment>
                ) : null,
              }}
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 2,
        }}
      >
        <Button
          onClick={() => {
            setCountDisplay(false);
            setPageState(1);
            setSongTypePref("canto");
            setSearchOn(false);
            setSearchText("");
            setSongList(normalSongArr.slice(0, 10));
            setPageCount(Math.ceil(Object.values(normalSongArr).length / 10));
            setPaginateArr(normalSongArr);
          }}
          disabled={songList.length === 0}
          sx={{
            mx: 1,
            backgroundColor: songTypePref == "canto" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          size="small"
          variant="contained"
        >
          Cantos
        </Button>
        <Button
          onClick={() => {
            setCountDisplay(false);
            setPageState(1);
            setSongTypePref("himno");
            setSearchOn(false);
            setSearchText("");
            setSongList(hymnSongArr.slice(0, 10));
            setPageCount(Math.ceil(Object.values(hymnSongArr).length / 10));
            setPaginateArr(hymnSongArr);
          }}
          disabled={songList.length === 0}
          sx={{
            mx: 1,
            backgroundColor: songTypePref == "himno" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          size="small"
          variant="contained"
        >
          Himnos
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SortByPopover
          execFunc={sortData}
          disabled={songList.length === 0}
          stateData={songTypePref}
        />
      </Box>

      {userID !== "" && (
        <LastModifiedByText email={LastAuthorEmail} time={LastAuthorTime} />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginY: 3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 360,
            border: 1,
            borderColor: "lightgrey",
          }}
        >
          <List disablePadding>
            {songList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    secondaryAction={
                      <ListItemText
                        style={{ color: darkMode ? "white" : "black" }}
                      >
                        {countDisplay ? item.performNum : null}
                      </ListItemText>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      sx={{ px: 2, py: 0 }}
                      onClick={(e) => handleListItemClick(e, item)}
                    >
                      <ListItemText
                        style={{ color: darkMode ? "white" : "black" }}
                        primary={item.name}
                        secondary={item?.author || "Desconocido"}
                      />
                    </ListItemButton>
                  </ListItem>
                  {index !== songList.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      </Box>
      {isLoading && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ pb: 5 }}
      >
        <Pagination
          count={pageCount}
          page={pageState}
          color="primary"
          onChange={(_, value) => {
            movePage(value);
            setPageState(value);
          }}
        />
      </Box>
      {userID !== "" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 3,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "green",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
            onClick={(e) => handleAddSong(e)}
          >
            Add
          </Button>
        </Box>
      )}
    </div>
  );
};

export default Repository;
